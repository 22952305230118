* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

input[type='range'] {
  -webkit-appearance: none;
  height: 1.5px;
  cursor: pointer;
  background: black;
  border-radius: 5px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  border: solid 1px black;
}

@media (max-width: 600px) {
  input[type='range'] {
    width: 65px;
  }
}
